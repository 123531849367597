@keyframes colorChange {
	0% {
		background-color: rgba($color-white, 1);
	}
    50% {
		background-color: rgba($color-white, 0.8);
    }
	100% {
        background-color: rgba($color-white, 1);
    }
}
